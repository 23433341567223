import React from 'react';
import ReactDOM from 'react-dom';
import App from 'components/App';
import { QueryClient, QueryClientProvider } from 'react-query';
import { queryClientOptions } from 'api/base';

const queryClient = new QueryClient(queryClientOptions);

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
