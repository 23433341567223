/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { getCarDetailRecord } from 'api/api';
import DetailItem from 'components/DetailItem';
import { Spinner } from 'react-bootstrap';

import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { parseDetailDataApi } from 'utils/filteredData';
import NotFound from 'pages/NotFound';
import { isEmpty } from 'lodash';

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import 'react-toastify/dist/ReactToastify.css';

const Detail = () => {
  const intl = useIntl();

  let { id } = useParams();
  id = id.split('-')[0]; // hyphens in url /id-title-sample

  const { isLoading, data } = useQuery(['carDetail', id], getCarDetailRecord, {
    enabled: !!id,
    select: (dataResponse) => parseDetailDataApi(dataResponse, intl),
  });

  if (isLoading) return <Spinner animation="border" variant="warning" role="status" className="app__spinner" />;

  if (!isLoading && !isEmpty(data)) return <DetailItem data={data} />;

  return <NotFound />;
};

export default Detail;
