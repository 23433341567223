import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

const Footer = () => {
  return (
    <>
      <div className="landing-footer">
        <div id="stopka" className="block-wrapper block-35-2-footer-dark">
          <section
            className="landing-semantic-background-color g-pt-40 g-bg-image g-pb-auto"
            style={{
              '--bg-url':
                'url("https://cdn.bitrix24.pl/b16609561/landing/906/906155bd7fcd3657cd1a063d3647d9f9/powerful-headlights-particle-view-of-modern-luxury-cars-parked-indoors-at-daytime_1x.jpg")',
              '--bg-url-2x':
                'url("https://cdn.bitrix24.pl/b16609561/landing/981/981c7832566644de6044b95d777476ac/powerful-headlights-particle-view-of-modern-luxury-cars-parked-indoors-at-daytime_2x.jpg")',
              '--bg-overlay': 'hsla(357, 75%, 56%, 0.9)',
              '--bg-size': 'auto',
              '--bg-attachment': 'scroll',
            }}
            data-fileid={175616}
            data-fileid2x={175618}
            data-lazy-styleimg="Y"
          >
            <div className="container">
              <div className="row">
                <div
                  className="landing-block-node-main-card js-animation fadeInLeft col-sm-12 col-md-6 col-lg-6 g-mb-25 g-mb-0--lg"
                  style={{ animationDuration: '1000ms', animationPlayState: 'running', animationName: 'none' }}
                >
                  <h2 className="landing-block-node-title landing-semantic-subtitle-small g-color-white g-font-weight-700 g-font-size-16 g-mb-20 g-text-transform-none">
                    kupauto24.de
                  </h2>
                  <div className="landing-block-node-text landing-semantic-text-medium g-mb-20 g-color-white">
                    <p>
                      AQURATA GmbH
                      <br />
                      Wannerstr. 41-41a
                      <br />
                      45888 Gelsenkirchen
                      <br />
                      Steuernummer: 319/5821/5292
                      <br />
                    </p>
                  </div>
                  <address className="g-mb-20">
                    <div
                      className="landing-block-card-contact g-pos-rel g-pl-20 g-mb-7 landing-card"
                      data-card-preset="text"
                    ></div>
                  </address>
                </div>
                <div
                  className="landing-block-node-column js-animation fadeInRight col-sm-12 col-md-2 col-lg-2 g-mb-25 g-mb-0--lg"
                  style={{ animationDuration: '1000ms', animationPlayState: 'running', animationName: 'none' }}
                >
                  <h2 className="landing-block-node-title landing-semantic-subtitle-small text-uppercase g-color-white g-font-weight-700 g-font-size-16 g-mb-20">
                    <FormattedMessage id="offerLabel" />
                  </h2>
                  <ul className="landing-block-card-list1 list-unstyled g-mb-30">
                    <li className="landing-block-card-list1-item g-mb-10">
                      <a
                        className="landing-block-node-list-item landing-semantic-menu-h-text g-color-white g-color-blue-dark-v1--hover"
                        href="https://kupauto24.de/wyszukiwarka/"
                        target="_self"
                      >
                        <FormattedMessage id="searchCarPageLabel" />
                      </a>
                    </li>
                    <li className="landing-block-card-list1-item g-mb-10">
                      <a
                        className="landing-block-node-list-item landing-semantic-menu-h-text g-color-white g-color-blue-dark-v1--hover"
                        href="https://kupauto24.de/sprzedanesamochody/"
                        target="_self"
                      >
                        <FormattedMessage id="carsSoldLabel" />
                      </a>
                    </li>
                    <li className="landing-block-card-list1-item g-mb-10">
                      <a
                        className="landing-block-node-list-item landing-semantic-menu-h-text g-color-white g-color-blue-dark-v1--hover"
                        href="https://kupauto24.de/carvertical/"
                        target="_self"
                      >
                        <FormattedMessage id="raportcarVerticalLabel" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  className="landing-block-node-column js-animation bounceInRight col-sm-12 col-md-2 col-lg-2 g-mb-25 g-mb-0--lg"
                  style={{ animationDuration: '1000ms', animationPlayState: 'running', animationName: 'none' }}
                >
                  <h2 className="landing-block-node-title landing-semantic-subtitle-small text-uppercase g-color-white g-font-weight-700 g-font-size-16 g-mb-20">
                    <FormattedMessage id="formsLabel" />
                  </h2>
                  <ul className="landing-block-card-list2 list-unstyled g-mb-30">
                    <li className="landing-block-card-list2-item g-mb-10">
                      <a
                        className="landing-block-node-list-item landing-semantic-menu-h-text g-color-white g-color-blue-dark-v1--hover"
                        href="https://kupauto24.de/#kontakt"
                        target="_self"
                      >
                        <FormattedMessage id="contactFormSingleLabel" />
                      </a>
                    </li>
                    <li className="landing-block-card-list2-item g-mb-10">
                      <a
                        className="landing-block-node-list-item landing-semantic-menu-h-text g-color-white g-color-blue-dark-v1--hover"
                        href="https://kupauto24.de/formularz-zapytania-o-samochod/"
                        target="_self"
                      >
                        <FormattedMessage id="contactAskSingleLabel" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  className="landing-block-node-column js-animation bounceInRight col-sm-12 col-md-2 col-lg-2"
                  style={{ animationDuration: '1000ms', animationPlayState: 'running', animationName: 'none' }}
                >
                  <h2 className="landing-block-node-title landing-semantic-subtitle-small text-uppercase g-color-white g-font-weight-700 g-font-size-16 g-mb-20">
                    <FormattedMessage id="linksLabel" />
                  </h2>
                  <ul className="landing-block-card-list1 list-unstyled g-mb-30">
                    <li className="landing-block-card-list3-item g-mb-10">
                      <a
                        className="landing-block-node-list-item landing-semantic-menu-h-text g-color-white g-color-blue-dark-v1--hover"
                        href="https://pomagamy.de"
                        target="_blank"
                      >
                        pomagamy.de
                      </a>
                    </li>
                    <li className="landing-block-card-list3-item g-mb-10">
                      <a
                        className="landing-block-node-list-item landing-semantic-menu-h-text g-color-white g-color-blue-dark-v1--hover"
                        href="https://www.facebook.com/kupauto24.de"
                        target="_blank"
                      >
                        Facebook
                      </a>
                    </li>
                    <li className="landing-block-card-list3-item g-mb-10">
                      <a
                        className="landing-block-node-list-item landing-semantic-menu-h-text g-color-white g-color-blue-dark-v1--hover"
                        href="https://www.instagram.com/kupauto24/"
                        target="_blank"
                      >
                        Instagram
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div id="b8104" className="block-wrapper block-17-copyright">
          <section
            className="landing-block js-animation animation-none animated g-pt-auto g-pb-auto"
            style={{
              '--bg': '',
              '--bg-url': '',
              '--bg-url-2x': '',
              '--bg-overlay': '',
              '--bg-size': '',
              '--bg-attachment': '',
              animationDuration: '1000ms',
              animationPlayState: 'running',
            }}
          >
            <div className="landing-semantic-text-medium text-center g-pa-10">
              <div className="g-width-600 mx-auto">
                <div
                  className="landing-block-node-text landing-semantic-text-medium js-animation animation-none animated g-font-weight-auto g-pl-auto g-pr-auto g-mb-auto g-pt-auto g-font-size-10"
                  style={{ '--color': '', animationDuration: '1000ms', animationPlayState: 'running' }}
                >
                  <p>
                    <a href="https://kupauto24.de/politykaprywatnosci/" target="_self">
                      <FormattedMessage id="privacyLinkLabel" />-
                    </a>{' '}
                    <a href="https://kupauto24.de/impressum/" target="_self">
                      Impressum
                    </a>
                    -{' '}
                    <a href="https://kupauto24.de/rodo/" target="_self">
                      RODO
                    </a>
                    -{' '}
                    <a href="https://kupauto24.de/regulamin/" target="_self">
                      <FormattedMessage id="regulationsLabel" />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default memo(Footer);
