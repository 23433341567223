import React from 'react';

import { useQuery } from 'react-query';

import { ReactQueryDevtools } from 'react-query/devtools';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from 'react-error-boundary';

import Home from 'pages/Home';
import Search from 'pages/Search';

import { LOCALES } from 'i18n/locales';

import { buildDetailPath, buildSearchPath } from 'utils/routes';
import Detail from 'pages/Detail';
import NotFound from 'pages/NotFound';

import Header from 'components/Header';
import Footer from 'components/Footer';
import { getTranslationMessages } from 'api/api';

import { Spinner } from 'react-bootstrap';
import Error from 'pages/Error';
import 'styles/app.scss';
import styles from './styles.module.scss';

const tmpLang = navigator.language || navigator.userLanguage;

const locale = tmpLang === 'de' ? LOCALES.GERMAN : LOCALES.POLISH;

const App = () => {
  const { isLoading: isLoadingMessages, data: dataMessages } = useQuery(['messages', locale], getTranslationMessages, {
    enabled: !!locale,
  });

  if (isLoadingMessages) return <Spinner animation="border" variant="warning" role="status" className="app__spinner" />;

  return (
    <div className={styles.App}>
      <IntlProvider locale={locale} messages={dataMessages?.data} defaultLocale={LOCALES.POLISH}>
        <ErrorBoundary
          FallbackComponent={Error}
          onReset={() => {
            window.location.replace('/search');
          }}
        >
          <HelmetProvider>
            <Helmet>
              <script async src="https://www.googletagmanager.com/gtag/js?id=G-XF7VT5JSJ4" data-skip-moving="true" />
            </Helmet>
          </HelmetProvider>

          <BrowserRouter>
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path={buildSearchPath('*')} element={<Search />} />
              <Route path={buildDetailPath(':id', ':slug')} element={<Detail />} />
              <Route path="*" element={<NotFound />} />
            </Routes>

            <Footer />
          </BrowserRouter>

          <ToastContainer closeOnClick closeButton={false} limit={3} />
          <ReactQueryDevtools initialIsOpen={false} />
        </ErrorBoundary>
      </IntlProvider>
    </div>
  );
};

export default App;
