import React, { useEffect } from 'react';
import { Col, Pagination, Row } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Paginate = ({ data: { maxPages, currentPage } }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleClick = (number) => {
    searchParams.set('page_number', number);

    navigate({ pathname: '/search', search: searchParams.toString() });
  };

  // Get form values from params
  useEffect(() => {
    if (maxPages < currentPage) {
      // remove pagination
      searchParams.delete('page_number');
      navigate({ pathname: '/search', search: searchParams.toString() });
    }
  }, []);

  // pagination config
  let startNumber = currentPage - 2;
  const maxItemsInPaginationMenu = 4;

  let items = [];

  startNumber = startNumber > 1 ? startNumber : 1;

  let lastPositionPaginationMenu = startNumber + maxItemsInPaginationMenu;
  lastPositionPaginationMenu = currentPage >= maxPages ? maxPages : lastPositionPaginationMenu;
  lastPositionPaginationMenu = lastPositionPaginationMenu >= maxPages ? maxPages : lastPositionPaginationMenu;

  for (let number = startNumber; number <= lastPositionPaginationMenu; number++) {
    items.push(
      <Pagination.Item key={number} active={number.toString() === currentPage} onClick={() => handleClick(number)}>
        {number}
      </Pagination.Item>,
    );
  }

  return (
    <Row>
      <Col>
        <div className="d-flex justify-content-center">
          <Pagination size="lg" className={styles.PaginationWrapper}>
            <Pagination.First onClick={() => handleClick(1)} disabled={currentPage === '1'} />
            <Pagination.Prev onClick={() => handleClick(currentPage - 1)} disabled={currentPage === '1'} />

            {items}

            <Pagination.Next
              onClick={() => handleClick(parseInt(currentPage, 10) + 1)}
              disabled={currentPage === maxPages}
            />
            <Pagination.Last onClick={() => handleClick(maxPages)} disabled={currentPage === maxPages} />
          </Pagination>
        </div>
      </Col>
    </Row>
  );
};

Paginate.propTypes = {
  data: PropTypes.shape({
    currentPage: PropTypes.string.isRequired,
    maxPages: PropTypes.string.isRequired,
  }).isRequired,
};

export default Paginate;
