import { range } from './utils';

export const localStorageKey = 'clipboard';

export const maxItemsClipboard = 10;

export const defaultCurrency = 'EUR';

export const interestRate = '4,99%';

export const suffixMetaTitle = 'kupauto24';

export const urlRules = 'http://info.kupauto24.de/warunki.pdf';

export const isParked = (clipboardItems, id) => clipboardItems.length > 0 && clipboardItems.find((e) => e.id === id);

// name checkboxes and mapping to refdata
export const checkboxFields = { wheelFormula: 'WHEEL_DRIVE_4x4' };

// Home page fields
export const basicFields = {
  mark: [],
  model: [],
  price_min: [],
  price_max: [],
  firstRegistrationDate_min: [],
  firstRegistrationDate_max: [],
  mileage_min: [],
  mileage_max: [],
  fuel: [],
};

// Search Page fields
export const extendedFields = {
  power_min: [],
  power_max: [],
  category: [],
  gearbox: [],
  sort_field: [],
  page_number: 1,
};

// Show More button fields
export const moreFields = {
  exteriorColor: [],
  climatisation: [],
  doorCount: [],
  damageUnrepaired: [],
};

// Show More button fields
export const defaultValues = {
  ...basicFields,
  ...extendedFields,
  ...moreFields,
};

export const defaultPaymentValues = {
  contributionPayment: 0,
  loanRepaymentPeriod: 84,
};

export const priceOptions = [
  '5000',
  '6000',
  '10000',
  '15000',
  '20000',
  '25000',
  '30000',
  '35000',
  '40000',
  '50000',
  '65000',
  '80000',
  '100000',
  '200000',
  '250000',
];

export const currentYear = new Date().getFullYear();

export const registerOptions = range(currentYear, currentYear - 50, -1);

export const mileageOptions = [
  '20000',
  '35000',
  '50000',
  '75000',
  '100000',
  '125000',
  '150000',
  '200000',
  '255000'
];

// kW
export const powerOptions = ['50', '80', '120', '150', '200', '300', '500'];

// sortowanie: cena, data dodania, rocznik
export const sortOptions = (intl) => {
  return [
    { key: 'price', label: intl.formatMessage({ id: 'sortPriceLowestLabel' }) },
    { key: 'priceMax', label: intl.formatMessage({ id: 'sortPriceHighestLabel' }) },
    { key: 'constructionYearMax', label: intl.formatMessage({ id: 'sortNewLabel' }) },
    { key: 'constructionYear', label: intl.formatMessage({ id: 'sortOldestLabel' }) },
    { key: 'mileage', label: intl.formatMessage({ id: 'sortMileageLabel' }) },
    { key: 'mileageMax', label: intl.formatMessage({ id: 'sortMileageMaxLabel' }) },
    { key: 'power', label: intl.formatMessage({ id: 'sortPowerLabel' }) },
    { key: 'powerMax', label: intl.formatMessage({ id: 'sortPowerMaxLabel' }) },
  ];
};

export const unitData = {
  mileage: 'km',
  power: 'KM',
  'cubic-capacity': 'm³',
};

export const getUnit = (key) => unitData[key];

export const damagedOptions = (intl) => {
  return [
    { key: '0', label: intl.formatMessage({ id: 'noLabel' }) },
    { key: '1', label: intl.formatMessage({ id: 'yesLabel' }) },
  ];
};

// # 28 Ukrycie elementów opisu w szczegółach
export const filteredSpecifics = (parsedSpecifics) =>
  parsedSpecifics &&
  parsedSpecifics.filter(({ label, value }) => value !== '' && !['SCHWACKE-CODELABEL'].includes(label.toUpperCase()));

// #43 dodanie numeru ogłoszenia
export const getIdRecord = (id) => `24${id}KA`;

export const generatePageAllStats = () => {
  const min = 1020000;
  const max = 1030000;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  const formattedNumber = randomNumber.toLocaleString('pl-PL');

  return formattedNumber;
};
