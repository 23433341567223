import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Image, Row } from 'react-bootstrap';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

const Tile = ({ item }) => {
  const navigate = useNavigate();

  const { id, title, url, thumb, subTitle, priceValue, currencyValue, rate, details } = item;

  const handleClick = () => {
    navigate(url);
  };

  return (
    <Row className={styles.Tile} key={id} onClick={handleClick} role="button">
      <Col md={3}>
        <Image src={thumb} fluid />
      </Col>

      <Col md={6}>
        <div className={styles.Title}>{title}</div>
        <em dangerouslySetInnerHTML={{ __html: subTitle }} />
        <br />

        {details && (
          <>
            {details.map(({ label, value }, i) => (
              <Fragment key={`tile${id}${label}`}>
                <b>
                  <FormattedMessage id={label} />
                </b>
                :&nbsp;{value}
                {i + 1 === details.length ? '' : ' - '}
              </Fragment>
            ))}
          </>
        )}
      </Col>

      <Col md={3}>
        <div className={styles.Buttons}>
          <Button variant="primary" className="w-100 mb-3">
            <FormattedMessage
              id="loanFromLabel"
              values={{
                value: rate,
                symbol: '€',
              }}
            />
          </Button>

          <Button variant="secondary" className="w-100 mb-3">
            <FormattedNumber value={priceValue} style="currency" currency={currencyValue} />
          </Button>
        </div>
      </Col>
    </Row>
  );
};

Tile.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired, // TODO: Shape
};

export default Tile;
