import React from 'react';

import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';

const DetailsList = ({ items }) => {
  return (
    <Row className={styles.DetailsWrapper}>
      {items.map(({ label, value, unit }) => (
        <Col key={label} md={4}>
          <b className="text-uppercase">
            <FormattedMessage id={label} />
          </b>
          : <span className="text-uppercase" dangerouslySetInnerHTML={{ __html: value }} /> {unit}
        </Col>
      ))}
    </Row>
  );
};

DetailsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DetailsList;
