import React, { useState } from 'react';
import { Button, Image, Modal, Table } from 'react-bootstrap';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { arrayExist, getMonthlyRate } from 'utils/utils';
import useLocalStorageState from 'use-local-storage-state';
import { defaultCurrency, localStorageKey, maxItemsClipboard } from 'utils/data';
import ContactFormModal from 'components/ContactFormModal';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Clipboard = ({ onHide, show }) => {
  const intl = useIntl();

  const [contactFormModalShow, setContactFormModalShow] = useState(false);

  const [clipboardItems, setClipboardItems] = useLocalStorageState(localStorageKey, {
    ssr: true,
    defaultValue: [],
  });

  const handleClickOnDeleteItem = (id) => {
    const itemsChanged = clipboardItems.filter((e) => e.id !== id) || [];

    setClipboardItems(itemsChanged);

    toast(intl.formatMessage({ id: 'deleteDoneLabel' }));
  };

  const handleClickOnSendInquiry = (e) => {
    setContactFormModalShow(true);
    onHide();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="left"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-primary">
            <FormattedMessage id="clipboardLabel" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-info text-center">
            <FormattedMessage id="clipboardMetaLabel" values={{ maxItemsClipboard }} />
          </div>

          {!arrayExist(clipboardItems) > 0 && (
            <div className="alert alert-info text-center">
              <FormattedMessage id="noRecordsInClipboardLabel" />
            </div>
          )}

          {arrayExist(clipboardItems) > 0 && (
            <>
              {clipboardItems.length >= maxItemsClipboard && (
                <p className="alert alert-info text-center">
                  <FormattedMessage id="clipboardLimitLabel" />
                </p>
              )}

              <Table responsive className="text-center table">
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="thumbLabel" />
                    </th>
                    <th>
                      <FormattedMessage id="nameLabel" />
                    </th>

                    <th>
                      <FormattedMessage id="priceLabel" />
                    </th>

                    <th>
                      <FormattedMessage id="yourLoanLabel" />
                    </th>

                    <th />
                  </tr>
                </thead>
                <tbody>
                  {clipboardItems.map(({ id, thumb, title, url, price }) => (
                    <tr key={id} className="align-middle">
                      <td id={`car${id}`}>
                        <Link to={url} onClick={onHide}>
                          <Image src={thumb} fluid style={{ height: '50px' }} />
                        </Link>
                      </td>
                      <td>
                        <Link to={url} onClick={onHide}>
                          {title}
                        </Link>
                      </td>

                      <td>
                        <FormattedNumber value={price} style="currency" currency={defaultCurrency} />
                      </td>

                      <td>
                        <span className="text-lowercase">
                          <FormattedMessage id="fromLabel" />
                        </span>{' '}
                        <FormattedNumber value={getMonthlyRate(price)} style="currency" currency={defaultCurrency} />
                      </td>

                      <td className="align-right">
                        <Button
                          onClick={() => handleClickOnDeleteItem(id)}
                          title={intl.formatMessage({ id: 'deleteLabel' })}
                        >
                          <i className="fa fa-trash" aria-hidden="true" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {clipboardItems.length > 0 && (
            <Button onClick={handleClickOnSendInquiry} variant="primary">
              <FormattedMessage id="sendInquiryLabel" />
            </Button>
          )}

          <Button onClick={onHide} variant="secondary">
            <FormattedMessage id="closeLabel" />
          </Button>
        </Modal.Footer>
      </Modal>

      <ContactFormModal show={contactFormModalShow} onHide={() => setContactFormModalShow(false)} />
    </>
  );
};

Clipboard.defaultProps = {
  show: false,
};

Clipboard.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

export default Clipboard;
