/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/sort-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { Controller } from 'react-hook-form';
import { Typeahead } from 'react-bootstrap-typeahead';

import { ToggleButton } from 'components/ToggleButton';

import { useIntl } from 'react-intl';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useFormContext } from 'react-hook-form';
import { parseTypeaheadOnChangeCustomOptionValue } from 'utils/utils';

const ControllerTypeahead = ({
  name,
  disabled,
  options,
  placeholderIdLabel,
  onChangeHandler,
  onBlurHandler,
  clearButton,
  allowNew,
  filterBy,
  multiple,
  open,
  inputProps,
}) => {
  const intl = useIntl();
  const { setValue } = useFormContext();

  return (
    <Controller
      name={name}
      render={({ field, fieldState, field: { value, onChange: onChangeFormHandler } }) => (
        <>
          <Typeahead
            {...field}
            disabled={disabled}
            id={`field-${name}`}
            options={options}
            placeholder={intl.formatMessage({ id: placeholderIdLabel })}
            clearButton={clearButton}
            paginate={false}
            className={fieldState.invalid ? 'is-invalid' : ''}
            selected={value}
            filterBy={filterBy}
            inputProps={inputProps}
            emptyLabel={intl.formatMessage({ id: 'emptyLabel' })}
            allowNew={allowNew}
            multiple={multiple}
            newSelectionPrefix=""
            open={open}
            maxResults={999}
            onChange={(val) => {
              onChangeFormHandler(parseTypeaheadOnChangeCustomOptionValue(val));
              setValue('page_number', 1);

              if (onChangeHandler) onChangeHandler(val);
            }}
            onBlur={(val) => {
              if (allowNew) setValue(name, [val.target.value]);

              if (onBlurHandler) onBlurHandler(val.target.value);
            }}
          >
            {({ isMenuShown, toggleMenu }) => (
              <ToggleButton
                isOpen={isMenuShown}
                onClick={(e) => {
                  e.preventDefault();
                  return toggleMenu();
                }}
              />
            )}
          </Typeahead>

          <p className="invalid-feedback">{fieldState.error?.message}</p>
        </>
      )}
    />
  );
};

ControllerTypeahead.defaultProps = {
  allowNew: false,
  onChangeHandler: null,
  onBlurHandler: null,
  filterBy: undefined,
  disabled: false,
  multiple: false,
  clearButton: false,
  inputProps: {},
};

ControllerTypeahead.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  allowNew: PropTypes.bool,
  disabled: PropTypes.bool,
  clearButton: PropTypes.bool,
  filterBy: PropTypes.func,
  multiple: PropTypes.bool,
  onChangeHandler: PropTypes.func,
  onBlurHandler: PropTypes.func,
  placeholderIdLabel: PropTypes.string.isRequired,
  inputProps: PropTypes.object, // TODO: shape
};

export default ControllerTypeahead;
