import axios from 'axios';
import PropTypes from 'prop-types';
import { isDev, parseField } from 'utils/utils';

export const baseURL = isDev() ? 'http://localhost:8000/api.php' : '/api.php';

export const queryClientOptions = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
};

export const defaultErrorHandler = (err) => {
  if (typeof err === 'undefined') return new Error('Unknown Error');
  if (typeof err === 'string') return new Error(err);

  return new Error(err?.message);
};

export const doRequest = async (targetUrl, requestOptions, rootUrl = baseURL) => {
  try {
    return await axios({
      url: `${rootUrl}`,
      ...requestOptions,
      params: { ...requestOptions?.params, targetUrl },
      data: requestOptions?.data,
      headers: {
        'Accept-Language': 'pl-PL',
      },
    });
  } catch (err) {
    throw defaultErrorHandler(err);
  }
};

export const getFlattenValues = (data) => {
  let obj = {};
  Object.entries(data).map(([key, value]) =>
    value && value[0] ? (obj[key] = parseField(value).toString()) : undefined,
  );

  return obj;
};

doRequest.defaultProps = {
  rootUrl: '',
};

doRequest.propTypes = {
  requestOptions: PropTypes.objectOf(PropTypes.object),
  rootUrl: PropTypes.string,
  targetUrl: PropTypes.string.isRequired,
};
