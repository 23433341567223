import { kebabCase } from 'lodash';
import {
  parseSpecificValue,
  arrayExist,
  getImageFromApi,
  getMonthlyRate,
  getPaginationData,
  getParsedPriceFromApi,
  getStringValueToBoolean,
  getTranslateValue,
  getValueFromApi,
  getYesNoLabel,
  getImagesToGallery,
} from 'utils/utils';
import { buildDetailPath } from './routes';
import { filteredSpecifics, getIdRecord, getUnit } from './data';

export const parseSearchSingleItem = (ad, intl) => {
  const {
    vehicle,
    price,
    images: {
      image: { representation },
    },
    '@key': id,
  } = ad;

  const { model, make, specifics } = vehicle;

  const title = `${getTranslateValue(make)} ${getTranslateValue(model)}`;

  const url = buildDetailPath(id, kebabCase(title));

  const thumb = getImageFromApi(representation, 'M');

  const subTitle = getValueFromApi(vehicle['model-description']);

  const priceValue = getParsedPriceFromApi(price['consumer-price-amount']);

  const currencyValue = price['@currency'];

  const rate = getMonthlyRate(priceValue);

  const isDamaged = getStringValueToBoolean(vehicle, 'damage-and-unrepaired');

  const damagedLabel = isDamaged ? 'isDamagedLabel' : 'isNotDamagedLabel';
  const damagedIntl = intl.formatMessage({ id: damagedLabel });

  // ! Intl
  const detailsBeforeFilter = [
    { label: 'first-registrationLabel', value: getValueFromApi(specifics['first-registration']) },
    { label: 'mileageLabel', value: getValueFromApi(specifics?.mileage) },
    { label: 'powerLabel', value: parseSpecificValue('power', specifics?.power) },
    { label: 'fuelLabel', value: getTranslateValue(specifics?.fuel) },
    { label: 'categoryLabel', value: getTranslateValue(specifics?.category) },
    { label: 'door-countLabel', value: getTranslateValue(specifics['door-count']) },
    { label: 'conditionLabel', value: damagedIntl },
    { label: 'exteriorColorLabel', value: getTranslateValue(specifics['exterior-color']) },
    { label: 'idRecordLabel', value: getIdRecord(id) },
  ];

  // Remove empty values
  const details = detailsBeforeFilter.filter(({ value }) => value !== '');

  return {
    id,
    title,
    url,
    thumb,
    subTitle,
    priceValue,
    currencyValue,
    rate,
    details,
  };
};

export const parseSearchData = (response, intl) => {
  let dataSearch = [];

  if (!response) return dataSearch;

  const { total, ads } = response.data['search-result'];

  const itemOrItems = ads?.ad; // object or array response (API)

  if (total >= 1 && !arrayExist(ads?.ad)) {
    // if only one record = object response
    dataSearch = [parseSearchSingleItem(item, intl)];
  } else if (total > 1) {
    dataSearch = itemOrItems.map((item) => parseSearchSingleItem(item, intl));
  }

  return dataSearch;
};

export const parseSearchDataApi = (response, intl) => {
  return { items: parseSearchData(response, intl), paginateData: getPaginationData(response) };
};

export const parseDetailDataApi = (response, intl) => {
  const item = response?.data?.ad;

  if (!item) return {};

  const {
    '@key': id,
    vehicle,
    images,
    price,
    'detail-page': { '@url': urlMobileDe },
  } = item;

  const { make, model, category, specifics, features: { feature } = {} } = vehicle;

  const title = `${getTranslateValue(make)} ${getTranslateValue(model)}`;

  const subTitle = getValueFromApi(vehicle['model-description']);

  const gallery = images?.image;

  const thumb = getImageFromApi(gallery[0]?.representation, 'M');

  const isDamaged = getStringValueToBoolean(vehicle, 'damage-and-unrepaired');

  const priceValue = getParsedPriceFromApi(price['consumer-price-amount']);
  const currencyValue = price['@currency'];
  const defaultMonthlyRate = getMonthlyRate(priceValue);

  // Details from specifics object (API)
  const parseSpecifics = (items) =>
    items
      ? Object.entries(items).map(([keyName, value]) => {
          return { label: `${keyName}Label`, value: parseSpecificValue(keyName, value), unit: getUnit(keyName) };
        })
      : [];

  const parsedSpecifics = parseSpecifics(specifics);
  const filteredParsedSpecifics = filteredSpecifics(parsedSpecifics);

  // Basic Labels:
  const detailsData = [
    { label: 'idRecordLabel', value: getIdRecord(id) },
    { label: 'markLabel', value: getTranslateValue(make) },
    { label: 'modelLabel', value: getTranslateValue(model) },
    { label: 'isDamagedLabel', value: intl.formatMessage({ id: getYesNoLabel(isDamaged) }) },
    { label: 'categoryLabel', value: getTranslateValue(category) },
    { label: 'modelDescriptionLabel', value: subTitle },
  ];

  const details = [...detailsData, ...filteredParsedSpecifics];

  const url = buildDetailPath(id, kebabCase(title));

  return {
    id,
    urlMobileDe,
    url,
    title,
    feature,
    subTitle,
    gallery: getImagesToGallery(gallery),
    thumb,
    isDamaged,
    priceValue,
    currencyValue,
    defaultMonthlyRate,
    details,
  };
};
