import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { urlRules } from 'utils/data';

import styles from './styles.module.scss';

const WhyUsSection = () => {
  const intl = useIntl();

  return (
    <>
      <Row className="mt-5 mb-3">
        <Col>
          <h3 className={styles.IntersectionTitle}>
            <FormattedMessage id="detailIntersectionLabel" />
          </h3>
        </Col>
      </Row>

      <Row className="mt-3 mb-3">
        <Col md={6}>
          <div
            className="app__list app__list--centered"
            dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'intersectionLeftList' }) }}
          />
        </Col>

        <Col md={6}>
          <div
            className="app__list"
            dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'intersectionRightList' }) }}
          />
        </Col>

        <Col md={12}>
          <p className="mt-4">
            <FormattedMessage
              id="rulesDescriptionLabel"
              values={{
                link: (
                  <a href={urlRules} target="_blank" rel="noreferrer">
                    <b>
                      <FormattedMessage id="rulesLinkLabel" />
                    </b>
                  </a>
                ),
              }}
            />
          </p>
        </Col>
      </Row>
    </>
  );
};

export default WhyUsSection;
