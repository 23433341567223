import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { arrayExist } from 'utils/utils';
import useLocalStorageState from 'use-local-storage-state';
import { localStorageKey } from 'utils/data';

const ContactFormModal = ({ onHide, show }) => {
  const [clipboardItems] = useLocalStorageState(localStorageKey, {
    ssr: true,
    defaultValue: [],
  });

  const initBitrix = () =>
    (function (w, d, u) {
      var s = d.createElement('script');
      s.async = true;
      s.src = u + '?' + ((Date.now() / 180000) | 0);
      var h = d.getElementsByTagName('script')[0];
      h.parentNode.insertBefore(s, h);
    })(window, document, 'https://cdn.bitrix24.pl/b16609561/crm/form/loader_120.js');

  useEffect(() => {
    initBitrix();

    const handleBitrixForm = (event) => {
      let form = event.detail.object;

      let link = window.location.href;
      if (arrayExist(clipboardItems)) {
        const urlSeparatedArr = clipboardItems.map(({ url }) => window.location.origin + url);
        link = urlSeparatedArr.join(' ');
      }

      if (form.identification.id === '120') {
        form.setProperty('link', link);
        clipboardItems.forEach(({ urlMobileDe }, index) => {
          form.setProperty(`link${index + 1}`, urlMobileDe);
        });
      }
    };

    window.addEventListener('b24:form:init', handleBitrixForm);

    return function cleanupListener() {
      window.removeEventListener('b24:form:init', handleBitrixForm);
    };
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="left"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-primary">
            <FormattedMessage id="sendInquiryLabel" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="bitrix-contact-form-modal">
            <script data-b24-form="inline/120/xqyj1l" data-skip-moving="true" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide} variant="secondary">
            <FormattedMessage id="closeLabel" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ContactFormModal.defaultProps = {
  show: false,
};

ContactFormModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

export default ContactFormModal;
