import React from 'react';
import { Alert, Col, Container, Row, Spinner } from 'react-bootstrap';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import SearchForm from 'components/SearchForm';

import { getSearchList } from 'api/api';
import { useQuery } from 'react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import Tile from 'components/Tile';
import { arrayExist } from 'utils/utils';
import { suffixMetaTitle } from 'utils/data';
import { parseSearchDataApi } from 'utils/filteredData';

import Paginate from 'components/Paginate';
import styles from './styles.module.scss';

const Search = () => {
  const intl = useIntl();
  const [searchParams] = useSearchParams();

  const { isLoading: isLoadingSearchList, data: dataSearchList } = useQuery(
    ['searchlist', Object.fromEntries(searchParams)],
    getSearchList,
    {
      select: (data) => parseSearchDataApi(data, intl),
    },
  );

  const parsedData = dataSearchList?.items;

  const isDataExist = !isLoadingSearchList && arrayExist(parsedData);
  const isNoData = !isLoadingSearchList && !arrayExist(parsedData);
  const title = `${intl.formatMessage({ id: 'searchTitleLabel' })} - ${suffixMetaTitle}`;

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
        </Helmet>
      </HelmetProvider>

      <div className={styles.Search}>
        <Container>
          <Row>
            <Col>
              <SearchForm extendedSearch />
            </Col>
          </Row>

          <div className="mt-5">
            {isLoadingSearchList && <Spinner className="app__spinner" animation="border" variant="warning" />}

            {isNoData && (
              <Alert variant="info">
                <FormattedMessage id="noRecordsLabel" />
              </Alert>
            )}

            {isDataExist > 0 && (
              <>
                {parsedData.map((item) => (
                  <Tile item={item} key={item.id} />
                ))}

                {<Paginate data={dataSearchList?.paginateData} />}
              </>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Search;
