import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import styles from './styles.module.scss';

const ColumnsList = () => {
  const intl = useIntl();

  return (
    <>
      <Row className="mt-5">
        {[1, 2, 3].map((val, index) => (
          <Col
            md={4}
            className={styles.ItemHomeBox}
            key={`columnList${index}`}
            onClick={() => (location = intl.formatMessage({ id: `url${index}` }))}
          >
            <div className="app__headings__column">{intl.formatMessage({ id: `heading${index}TitleLabel` })}</div>

            <p className="mt-3 mb-3">{intl.formatMessage({ id: `heading${index}MetaLabel` })}</p>

            <Image src={intl.formatMessage({ id: `img${index}` })} fluid />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default ColumnsList;
