import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { buildSearchPath } from 'utils/routes';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

const NotFound = () => {
  return (
    <div className={styles.NotFound}>
      <Container>
        <Row>
          <Col>
            <FormattedMessage id="notFoundLabel" />
          </Col>
        </Row>

        <Row>
          <Col>
            <Link to={buildSearchPath('')} className="text-center">
              <i className="fa fa-angle-double-left" aria-hidden="true" /> <FormattedMessage id="backToSearchLabel" />
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotFound;
