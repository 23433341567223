import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Col, Container, Image, Row } from 'react-bootstrap';
import SearchForm from 'components/SearchForm';

import { FormattedMessage, useIntl } from 'react-intl';
import klienciImg from 'assets/klienci.png';
import { urlRules } from 'utils/data';

import ColumnsList from 'components/ColumnsList';
import styles from './styles.module.scss';

const Home = () => {
  const intl = useIntl();

  return (
    <div className={styles.Home}>
      <Container>
        <Row>
          <Col>
            <SearchForm extendedSearch={false} />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md={7}>
            <h3 className="app__headings__main mb-3">
              <FormattedMessage id="detailIntersectionLabel" />
            </h3>

            <p>
              <FormattedMessage id="whyUsExcerptLabel" />
            </p>

            <div
              className="app__list"
              dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'intersectionLeftList' }) }}
            />

            <div
              className="app__list"
              dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'intersectionRightList' }) }}
            />

            <p className="mt-4">
              <FormattedMessage id="whyUsSummaryLabel" />
            </p>

            <p className="mt-4">
              <FormattedMessage
                id="rulesDescriptionLabel"
                values={{
                  link: (
                    <a href={urlRules} target="_blank" rel="noreferrer">
                      <b>
                        <FormattedMessage id="rulesLinkLabel" />
                      </b>
                    </a>
                  ),
                }}
              />
            </p>
          </Col>

          <Col md={5}>
            <Image src={klienciImg} fluid />
          </Col>
        </Row>

        <ColumnsList />

        <Row>
          <Col>
            <div className={styles.Motto}>
              <FormattedMessage id="mottoLabel" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Home;
