import React from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';

const ToggleButton = ({ isOpen, onClick }) => (
  <button
    className={styles.toggleButton}
    onClick={onClick}
    onMouseDown={(e) => {
      // Prevent input from losing focus.
      e.preventDefault();
    }}
  >
    {isOpen ? '▲' : '▼'}
  </button>
);

ToggleButton.defaultProps = {
  isOpen: false,
};

ToggleButton.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default ToggleButton;
