/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import ImageGallery from 'react-image-gallery';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { FacebookIcon, FacebookShareButton } from 'react-share';
import { toast } from 'react-toastify';

import { ReactComponent as LinkSvg } from 'assets/link.svg';
import Clipboard from 'components/Clipboard';
import WhyUsSection from 'components/WhyUsSection';

import useLocalStorageState from 'use-local-storage-state';
import {
  defaultPaymentValues,
  interestRate,
  isParked,
  localStorageKey,
  maxItemsClipboard,
  suffixMetaTitle,
} from 'utils/data';
import { arrayExist, getMonthlyRate } from 'utils/utils';

import DetailsList from 'components/DetailsList';
import FeaturesList from 'components/FeaturesList';

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import 'react-toastify/dist/ReactToastify.css';

import styles from './styles.module.scss';

const DetailItem = ({ data }) => {
  const {
    id,
    urlMobileDe,
    url,
    title,
    feature,
    subTitle,
    gallery,
    thumb,
    isDamaged,
    priceValue,
    currencyValue,
    defaultMonthlyRate,
    details,
  } = data;

  const intl = useIntl();
  const methods = useForm({ defaultValues: defaultPaymentValues });
  const navigate = useNavigate();
  const { register, handleSubmit } = methods;

  const [monthsValue, setMonthsValue] = useState(96);
  const [contributionPaymentValue, setContributionPaymentValue] = useState(0);
  const [monthlyRateValue, setMonthlyRateValue] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [clipboardItems, setClipboardItems] = useLocalStorageState(localStorageKey, {
    ssr: true,
    defaultValue: [],
  });

  const initBitrix = () =>
    (function (w, d, u) {
      const s = d.createElement('script');
      s.async = true;
      s.src = `${u}?${(Date.now() / 180000) | 0}`;
      const h = d.getElementsByTagName('script')[0];
      h.parentNode.insertBefore(s, h);
    })(window, document, 'https://cdn.bitrix24.pl/b16609561/crm/form/loader_128.js');

  useEffect(() => {
    initBitrix();

    const handleBitrix = (event) => {
      const form = event.detail.object;

      const link = window.location.href;

      const rata = monthlyRateValue || window.globalMonthlyRate;
      const okres = monthsValue;
      const wplata_wlasna = contributionPaymentValue;

      if (form.identification.id === '128') {
        form.setProperty('link', link);
        form.setProperty('link1', window.linkMobileDe); // #18 link 1 = link_mobile_de
        form.setProperty('okres', okres);
        form.setProperty('wplata_wlasna', wplata_wlasna);
        form.setProperty('rata', rata);
      }
    };

    window.addEventListener('b24:form:init', handleBitrix);

    return function cleanupListener() {
      window.removeEventListener('b24:form:init', handleBitrix);
    };
  }, [monthlyRateValue]);

  const { slug } = useParams();

  window.globalMonthlyRate = defaultMonthlyRate; // for bitrix
  window.linkMobileDe = urlMobileDe; // for bitrix 2

  useEffect(() => {
    window.scrollTo(0, 0);

    if (slug === '301') {
      // redirect: api request limit
      navigate(url);
    }
  }, []);

  const onSubmit = ({ contributionPayment }) => {
    const monthlyRate = getMonthlyRate(priceValue, monthsValue, contributionPayment);
    setMonthlyRateValue(monthlyRate);
  };

  // actions
  const handleClickOnClipboard = () => {
    setModalShow(true);
  };

  const handleClickOnParkIt = (e) => {
    if (clipboardItems && (clipboardItems.length >= maxItemsClipboard || isParked(clipboardItems, id))) {
      return;
    }

    const localStorageitem = { id, title, thumb, url: window.location.pathname, price: priceValue, urlMobileDe };

    setClipboardItems([...clipboardItems, localStorageitem]);

    toast(({ closeToast }) => (
      <div>
        {intl.formatMessage({ id: 'parkItDoneMessengerLabel' }, { linebreak: <br /> })}
        <Button variant="secondary" className="w-100 mt-3 btn-sm" onClick={handleClickOnClipboard}>
          <FormattedMessage id="goToClipboardLabel" />
        </Button>
        <Button variant="secondary" className="w-100 mt-3 btn-sm" onClick={closeToast}>
          <FormattedMessage id="continueLabel" />
        </Button>
      </div>
    ));
  };

  const handleClickOnShareLink = (e) => {
    e.preventDefault();

    const el = document.createElement('input');
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    toast(intl.formatMessage({ id: 'copyLinkDoneLabel' }));
  };

  const isParkedItem = isParked(clipboardItems, id);

  return (
    <div className={styles.Detail}>
      <HelmetProvider>
        <Helmet>
          <title>
            {title} - {suffixMetaTitle}
          </title>
          <meta name="description" content={`${title} - ${subTitle}`} />
        </Helmet>
      </HelmetProvider>

      <Container>
        <Row className="mb-3">
          <Col>
            <button
              role="button"
              className="btn fw-normal btn-default text-dark text-decoration-none"
              onClick={() => navigate(-1)}
            >
              <i className="fa fa-angle-double-left" aria-hidden="true" /> <FormattedMessage id="backToSearchLabel" />
            </button>
          </Col>
        </Row>

        <Row>
          <Col>
            <h1 className="text-primary">{title}</h1>
            <h2 className="text-primary" dangerouslySetInnerHTML={{ __html: subTitle }} />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={9}>
            <ImageGallery
              showIndex
              fullscreen
              loading="eager"
              items={gallery}
              additionalClass={styles.AdditionalClassImageGallery}
            />
          </Col>

          <Col md={3}>
            <Button variant="secondary" className="w-100 mb-3 btn-lg" onClick={handleClickOnClipboard}>
              <FormattedMessage id="clipboardLabel" /> ({clipboardItems.length})
            </Button>

            <Button variant="primary" className="w-100 mb-3 btn-lg">
              <FormattedNumber value={priceValue} style="currency" currency={currencyValue} />
            </Button>

            <Button variant="primary" className="w-100 mb-3 text-lowercase btn-lg">
              <FormattedNumber
                value={monthlyRateValue !== false ? monthlyRateValue : defaultMonthlyRate}
                // eslint-disable-next-line react/style-prop-object
                style="currency"
                currency={currencyValue}
              />
              <br />
              <FormattedMessage id="monthlyLabel" />
            </Button>

            <div className="w-100 mb-3">
              <div className={styles.WrapperFormLoan}>
                <FormProvider {...methods}>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.TitleFormLoan}>
                      <FormattedMessage id="yourLoanLabel" /> *
                    </div>

                    <Form.Label htmlFor="field-contribution">
                      <FormattedMessage id="contributionLabel" />
                    </Form.Label>
                    <Form.Control
                      min={0}
                      value={contributionPaymentValue}
                      max={priceValue}
                      type="number"
                      id="field-contribution"
                      {...register('contributionPayment', {
                        required: true,
                        onChange: (e) => {
                          if (e.target.checkValidity()) {
                            setContributionPaymentValue(e.target.value);
                          } else {
                            const priceFormatted = intl.formatNumber(priceValue);

                            toast(intl.formatMessage({ id: 'priceNotAllowedLabel' }, { priceFormatted }));
                          }
                        },
                      })}
                    />

                    <Form.Label htmlFor="field-loanRepaymentPeriod" className="mt-3">
                      <FormattedMessage id="loanRepaymentPeriodLabel" />: <strong>{monthsValue}</strong>
                    </Form.Label>

                    <RangeSlider
                      value={monthsValue}
                      onChange={(e) => {
                        setMonthsValue(e.target.value);
                      }}
                      tooltip="off"
                      id="field-loanRepaymentPeriod"
                      min={12}
                      max={96}
                      step={12}
                      variant="danger"
                    />

                    <Button variant="primary" type="submit" className="w-100 btn-lg">
                      <FormattedMessage id="calculateLabel" />
                    </Button>
                  </Form>
                </FormProvider>

                <div className={styles.InfoInterest}>
                  * <FormattedMessage id="infoInterestLabel" values={{ interestRate }} />
                </div>
              </div>
            </div>

            <Button
              variant="secondary"
              className="w-100 mb-3 btn-lg"
              onClick={handleClickOnParkIt}
              disabled={isParkedItem}
            >
              <FormattedMessage id={isParkedItem ? 'parkItDoneLabel' : 'parkItLabel'} />
            </Button>

            <Button variant="secondary" className="w-100 mb-3 btn-lg" href="tel:004920995509002">
              <FormattedMessage id="callLabel" />
            </Button>

            <Link to="#bitrix-form">
              <Button variant="secondary" className="w-100 mb-3 btn-lg">
                <FormattedMessage id="sendInquiryLabel" />
              </Button>
            </Link>

            <div className={styles.ShareWrapper}>
              <div className={styles.ShareItem}>
                <div>
                  <FormattedMessage id="shareLabel" />
                </div>

                <FacebookShareButton
                  url={window.location.href}
                  quote={title}
                  hashtag={`#${title}`}
                  description={subTitle}
                  title="Facebook"
                  className={styles.ShareIco}
                >
                  <FacebookIcon size={32} round bgStyle={{ fill: styles.primary }} />
                </FacebookShareButton>
              </div>

              <div className={styles.ShareItem}>
                <div>
                  <FormattedMessage id="copyLinkLabel" />
                </div>

                <a
                  href="#"
                  role="button"
                  onClick={handleClickOnShareLink}
                  title={intl.formatMessage({ id: 'copyLinkLabel' })}
                  className={styles.ShareIco}
                >
                  <LinkSvg style={{ height: '31px' }} />
                </a>
              </div>
            </div>
          </Col>
        </Row>
        {arrayExist(details) && <DetailsList items={details} />}

        {isDamaged && (
          <>
            <Row className="mt-5 mb-3">
              <Col>
                <h3 className={styles.IntersectionTitle}>
                  <FormattedMessage id="damagedTitleLabel" />
                </h3>
              </Col>
            </Row>

            <Row>
              <Col>
                <p>
                  <FormattedMessage id="damagedDescriptionLabel" />
                </p>
              </Col>
            </Row>
          </>
        )}

        <WhyUsSection />

        {arrayExist(feature) && <FeaturesList items={feature} />}

        <Row className="mt-5 mb-3">
          <Col>
            <h3 className={styles.ContactTitle}>
              <FormattedMessage id="sendInquiryLabel" />:
            </h3>
          </Col>
        </Row>

        <Row className="mt-5 mb-3 text-center">
          <Col>
            <h4>
              <FormattedMessage id="sendInquirySubheaderLabel" />
            </h4>
          </Col>
        </Row>

        <Row>
          <Col>
            <div id="bitrix-form">
              <script data-b24-form="inline/128/ufk3pu" data-skip-moving="true" />
            </div>
          </Col>
        </Row>
      </Container>

      <Clipboard show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

DetailItem.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DetailItem;
