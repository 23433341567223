import React from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import styles from './styles.module.scss';

const Error = ({ error, resetErrorBoundary, resetComponentState }) => {
  console.log(error.message);

  return (
    <div className={styles.Error}>
      <Container>
        <Row>
          <Col>
            <Alert>
              <FormattedMessage id="errorLabel" />

              <button
                onClick={() => {
                  resetComponentState();
                  resetErrorBoundary();
                }}
              >
                <FormattedMessage id="tryAgainLabel" />
              </button>
            </Alert>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Error;
