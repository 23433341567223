export const isDev = () => process.env.NODE_ENV === 'development';

export const getYesNoLabel = (bool) => (bool ? 'yesLabel' : 'noLabel');

export const arrayExist = (arr) => arr && arr.length;

export const numberWithSpaces = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step + '');

export const getTranslateValue = (val) =>
  val && val['local-description']
    ? customTranslateFromApi(val['local-description']['$'])
    : val && val['$']
    ? val['$']
    : '';

// Different Label from translated API response: API != APP (1 exception)
export const customTranslateFromApi = (val) => (val === 'Olej napędowy' ? 'Diesel' : val);

export const getValueFromApi = (val) => (val && val['@value'] ? val['@value'] : '');

export const getParsedPriceFromApi = (price) =>
  Number.parseFloat(getValueFromApi(price)) + Number.parseFloat(process.env.ADDITIONAL_FEES);

export const getImageFromApi = (items, size) => items.find((e) => e['@size'] === size)['@url'];

// Readme: translation structure API mobile.de
export const flattenObjToFormOptions = (items) =>
  items
    ? Object.entries(items).map(([_, value]) => {
        return { key: value['@key'], label: getTranslateValue(value) };
      })
    : [];

export const isObject = (val) => {
  if (val === null) {
    return false;
  }
  return typeof val === 'function' || typeof val === 'object';
};

export const translateFieldToKey = (val) => (isObject(val) ? val.key : val);

// api mobile.de return @value as string : "true" | "false"
export const getStringValueToBoolean = (item, keyString) =>
  item[keyString] && getValueFromApi(item[keyString]) === 'true' ? getValueFromApi(item[keyString]) : false;

export const parseField = (val) => val.map((item) => translateFieldToKey(item));

export const typeaheadShowAllOptions = (option, props) => {
  if (props.selected.length) {
    // Display all the options if there's a selection.
    return true;
  }
  // Otherwise filter on some criteria.
  return option.label.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
};

export const getPaginationData = (response) => {
  let dataSearch = {};

  if (!response || !response.data['search-result']) return dataSearch;

  const dataSource = response.data['search-result'];

  dataSearch = {
    maxPages: dataSource['max-pages'],
    currentPage: dataSource['current-page'],
    totalItems: dataSource.total,
  };

  return dataSearch;
};

export const parseSpecificValue = (keyName, value) => {
  const dataTranslateValue = getTranslateValue(value);
  const dataVal = getValueFromApi(value);

  let data = dataTranslateValue || dataVal;

  // convert kW to km (only one field)
  if (keyName === 'power' && data > 0) {
    data = Math.round(data * 1.36);
  }

  // format mileage field
  if (keyName === 'mileage') {
    data = numberWithSpaces(data);
  }

  return data;
};

// Change rate 1.2443 or max number of months (84)
export const getMonthlyRate = (price = 1, months = 84, contribution = 0) => {
  const parsedPrice = parseInt(price, 10);
  const parsedContribution = parseInt(contribution, 10);
  const parsedMonths = parseInt(months, 10);

  const ret = Math.round(
    ((parsedPrice - (parsedContribution >= parsedPrice ? parsedPrice - 1 : parsedContribution)) * 1.2443) /
      (parsedMonths > 84 ? 84 : parsedMonths),
  );

  return ret;
};

export const getImagesToGallery = (items) =>
  items
    ? Object.entries(items).map(([_, { representation }]) => {
        return {
          original: getImageFromApi(representation, 'XXL'),
          thumbnail: getImageFromApi(representation, 'S'),
        };
      })
    : [];

export const isCustomOptionValue = (val) => val && val[0]?.customOption;

export const parseTypeaheadOnChangeCustomOptionValue = (val) => (isCustomOptionValue(val) ? [val[0]?.label] : val);

export const isNewFormatCustomId = (val) => {
  const strVal = val.toString().toLowerCase();
  return strVal.startsWith('24') && strVal.endsWith('ka');
};

export const isCustomId = (val) => /^\d+$/.test(val) || isNewFormatCustomId(val);
