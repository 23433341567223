import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { Controller, useFormContext } from 'react-hook-form';

import { typeaheadShowAllOptions } from 'utils/utils';
import { powerOptions, sortOptions } from 'utils/data';
import ControllerTypeahead from 'components/ControllerTypeahead';

import { getSearchList } from 'api/api';

import styles from './styles.module.scss';

const ExtendedSearchFields = ({
  gearboxOptions,
  categoryOptions,
  colorOptions,
  climatisationOptions,
  doorCountOptions,
  toggleButton,
  onClickSubmit,
  isDisabledSubmit,
  isVisibleMoreFilters,
}) => {
  const intl = useIntl();
  const [searchParams] = useSearchParams();
  const { reset } = useFormContext();

  const { isLoading: isLoadingSearchList, data: dataSearchList } = useQuery(
    ['searchlist', Object.fromEntries(searchParams)],
    getSearchList,
  );

  const itemsCounter = dataSearchList?.data['search-result']?.total;

  return (
    <>
      <Row>
        <Col md={3}>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="field-power">
              <FormattedMessage id="powerEngineLabel" /> (KM)
            </Form.Label>

            <Row>
              <Col md={6}>
                <ControllerTypeahead
                  name="power_min"
                  options={powerOptions}
                  placeholderIdLabel="fromLabel"
                  clearButton
                  allowNew
                />
              </Col>

              <Col md={6}>
                <ControllerTypeahead
                  name="power_max"
                  options={powerOptions}
                  placeholderIdLabel="toLabel"
                  clearButton
                  allowNew
                />
              </Col>
            </Row>
          </Form.Group>
        </Col>

        <Col md={3}>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="field-model">
              <FormattedMessage id="categoryLabel" />
            </Form.Label>

            <ControllerTypeahead
              name="category"
              options={categoryOptions}
              placeholderIdLabel="categoryLabel"
              clearButton
              multiple
            />
          </Form.Group>
        </Col>

        <Col md={3}>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="field-model">
              <FormattedMessage id="gearboxLabel" />
            </Form.Label>

            <ControllerTypeahead
              name="gearbox"
              options={gearboxOptions}
              placeholderIdLabel="gearboxLabel"
              clearButton
              multiple
            />
          </Form.Group>
        </Col>

        <Col md={3} className="d-flex">
          <Button
            variant="primary"
            type="submit"
            className="mb-3 align-self-end w-100"
            onClick={onClickSubmit}
            disabled={isDisabledSubmit}
          >
            <FormattedMessage id="submitLabel" />
          </Button>
        </Col>
      </Row>

      {isVisibleMoreFilters && (
        <>
          <Row>
            <Col md={3}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="field-exterior-color">
                  <FormattedMessage id="exteriorColorLabel" />
                </Form.Label>

                <ControllerTypeahead
                  name="exteriorColor"
                  options={colorOptions}
                  placeholderIdLabel="exteriorColorLabel"
                  clearButton
                  filterBy={typeaheadShowAllOptions}
                />
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="field-climatisation">
                  <FormattedMessage id="climatisationLabel" />
                </Form.Label>

                <ControllerTypeahead
                  name="climatisation"
                  options={climatisationOptions}
                  placeholderIdLabel="climatisationLabel"
                  clearButton
                  filterBy={typeaheadShowAllOptions}
                />
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="field-doorCount">
                  <FormattedMessage id="doorCountLabel" />
                </Form.Label>

                <ControllerTypeahead
                  name="doorCount"
                  options={doorCountOptions}
                  placeholderIdLabel="doorCountLabel"
                  clearButton
                  filterBy={typeaheadShowAllOptions}
                />
              </Form.Group>
            </Col>

            <Col md={3}></Col>
          </Row>
        </>
      )}

      <Row>
        <Col md={9} />
        <Col md={3} className="text-center">
          <Button variant="secondary" type="submit" className="mb-3 align-self-end w-100" onClick={toggleButton}>
            {intl.formatMessage({ id: isVisibleMoreFilters ? 'hideMoreFiltersLabel' : 'showMoreFiltersLabel' })}
          </Button>
        </Col>
      </Row>

      {searchParams.toString() && (
        <Row>
          <Col md={9} />
          <Col md={3} className="text-center">
            <Button variant="secondary" type="button" className="w-100" onClick={() => reset()}>
              <FormattedMessage id="clearFiltersLabel" />
            </Button>
          </Col>
        </Row>
      )}

      <Row className="mt-5">
        <Col md={9}>
          <h3 className="text-primary">
            <FormattedMessage id="searchHeadingLabel" values={{ itemsCounter: itemsCounter || '...' }} />
          </h3>
        </Col>

        <Col md={3}>
          <Row>
            <Col className={styles.SortFieldColumn}>
              <ControllerTypeahead
                name="sort_field"
                options={sortOptions(intl)}
                placeholderIdLabel="sortLabel"
                clearButton
                filterBy={typeaheadShowAllOptions}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

ExtendedSearchFields.defaultProps = {
  categoryOptions: [],
  colorOptions: [],
  gearboxOptions: [],
  climatisationOptions: [],
  doorCountOptions: [],
};

ExtendedSearchFields.propTypes = {
  categoryOptions: PropTypes.arrayOf(PropTypes.object),
  climatisationOptions: PropTypes.arrayOf(PropTypes.object),
  colorOptions: PropTypes.arrayOf(PropTypes.object),
  doorCountOptions: PropTypes.arrayOf(PropTypes.object),
  gearboxOptions: PropTypes.arrayOf(PropTypes.object),
  isDisabledSubmit: PropTypes.bool.isRequired,
  isVisibleMoreFilters: PropTypes.bool.isRequired,
  onClickSubmit: PropTypes.func.isRequired,
  toggleButton: PropTypes.func.isRequired,
};

export default ExtendedSearchFields;
