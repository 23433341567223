import React from 'react';

import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';
import { getTranslateValue } from 'utils/utils';

const FeaturesList = ({ items }) => {
  return (
    <>
      <Row className="mt-5 mb-3">
        <Col>
          <h3 className={styles.EquipmentTitle}>
            <FormattedMessage id="equipmentLabel" />:
          </h3>
        </Col>
      </Row>

      <Row>
        {items.map((featureItem) => (
          <Col key={featureItem['@key']} md={3}>
            {getTranslateValue(featureItem)}
          </Col>
        ))}
      </Row>

      <hr className="mt-5" />
    </>
  );
};

FeaturesList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default FeaturesList;
