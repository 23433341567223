import Clipboard from 'components/Clipboard';
import React, { memo, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactGA from 'react-ga4';

import useLocalStorageState from 'use-local-storage-state';
import { localStorageKey } from 'utils/data';
import { useLocation } from 'react-router-dom';

ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID);

const Header = () => {
  const [modalShow, setModalShow] = useState(false);
  const [isActive, setActive] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const { pathname, search } = location;

    ReactGA.send({ hitType: 'pageview', page: pathname + search });
  }, [location]);

  const [clipboardItems] = useLocalStorageState(localStorageKey, {
    ssr: true,
    defaultValue: [],
  });

  const handleClickOnClipboard = (e) => {
    setModalShow(true);
  };

  // missing scripts from bitrix ?
  const handleToggleHamburger = () => {
    setActive(!isActive);
  };

  return (
    <>
      <div className="landing-header">
        <div id="b8100" className="block-wrapper block-0-menu-16" style={{ height: 'auto' }}>
          <header
            className="landing-block u-header u-header--relative u-header--sticky js-header-in-flow"
            style={{ top: '0px' }}
          >
            <div
              className="landing-block-node-top-block landing-semantic-background-color u-header__section u-header__section--hidden u-header__section--light g-bg-white g-brd-bottom g-brd-gray-light-v4 g-py-10 g-py-20--sm"
              style={{ '--bg': '' }}
            >
              <div className="container">
                <div className="row no-gutters flex-lg-row align-items-center justify-content-lg-start">
                  <div className="col-12 col-sm-3 col-lg-2 text-center text-md-left">
                    <a
                      href="https://kupauto24.de/"
                      className="navbar-brand landing-block-node-menu-logo-link g-mb-10 g-mb-0--sm g-mr-0"
                    >
                      <img
                        className="landing-block-node-menu-logo img-fluid"
                        src="https://pliki.kupauto24.de/logo/logo-kupauto24.png"
                        alt="Logo"
                        data-fileid={169414}
                        data-fileid2x={169416}
                        data-pseudo-url='{"text":"","href":"","target":"_self","enabled":false}'
                        data-lazy-img="Y"
                        loading="lazy"
                        srcSet="https://pliki.kupauto24.de/logo/logo-kupauto24.png 2x"
                      />
                    </a>
                  </div>
                  <div className="col-12 col-sm-9 col-lg-10">
                    <div className="row g-ml-20--sm">
                      <div className="landing-block-card-menu-contact-container col-sm-8 col-md-9">
                        <div className="landing-block-card-menu-contact-container-inner row">
                          <div
                            className="landing-block-card-menu-contact col-md g-mb-10 g-mb-0--md g-brd-right--md g-brd-gray-light-v4 landing-card"
                            data-card-preset="contact-link"
                          >
                            <a
                              href="tel:004920995509002"
                              className="landing-block-node-menu-contactlink-link g-pa-10--md row align-items-center justify-content-center justify-content-sm-start justify-content-md-center justify-content-lg-start g-text-decoration-none--hover text-decoration-none"
                              target="_self"
                            >
                              <span className="landing-block-node-menu-contact-img-container text-lg-left g-font-size-18 g-line-height-1 d-none d-sm-inline-block g-color-primary col-12 g-pa-0">
                                <i
                                  className="landing-block-node-menu-contact-img icon fa fa-phone"
                                  data-pseudo-url='{"text":"","href":"","target":"_self","enabled":false}'
                                />
                              </span>
                              <span className="landing-block-node-menu-contactlink-text-container text-lg-left d-inline-block col-12 g-pa-0">
                                <span className="landing-block-node-menu-contactlink-title landing-block-node-menu-contact-title-style landing-semantic-text-medium g-color-main d-block text-uppercase g-font-size-13">
                                  <FormattedMessage id="phoneGermanLabel" />
                                </span>
                                <span className="landing-block-node-menu-contactlink-text landing-block-node-menu-contact-text-style landing-semantic-link-small d-block g-color-main g-font-weight-700 g-text-decoration-none g-text-underline--hover">
                                  0209 9550 9002
                                </span>
                              </span>
                            </a>
                          </div>
                          <div
                            className="landing-block-card-menu-contact col-md g-mb-10 g-mb-0--md g-brd-right--md g-brd-gray-light-v4"
                            data-card-preset="contact-link"
                          >
                            <a
                              href="tel:0048325550242"
                              className="landing-block-node-menu-contactlink-link g-pa-10--md row align-items-center justify-content-center justify-content-sm-start justify-content-md-center justify-content-lg-start g-text-decoration-none--hover text-decoration-none"
                              target="_self"
                            >
                              <span className="landing-block-node-menu-contact-img-container text-lg-left g-font-size-18 g-line-height-1 d-none d-sm-inline-block g-color-primary col-12 g-pa-0">
                                <i
                                  className="landing-block-node-menu-contactlink-img icon fa fa-phone"
                                  data-pseudo-url='{"text":"","href":"","target":"_self","enabled":false}'
                                />
                              </span>
                              <span className="landing-block-node-menu-contactlink-text-container text-sm-left text-lg-left d-inline-block col-12 g-pa-0">
                                <span className="landing-block-node-menu-contactlink-title  landing-block-node-menu-contact-title-style landing-semantic-text-medium g-color-main d-block text-uppercase g-font-size-13">
                                  <FormattedMessage id="polishGermanLabel" />
                                </span>
                                <span className="landing-block-node-menu-contactlink-text landing-block-node-menu-contact-text-style landing-semantic-link-small d-block g-color-main g-font-weight-700 g-text-decoration-none g-text-underline--hover">
                                  32 555 0242
                                </span>
                              </span>
                            </a>
                          </div>
                          <div
                            className="landing-block-card-menu-contact col-md g-mb-10 g-mb-0--md g-brd-right--md g-brd-gray-light-v4"
                            data-card-preset="contact-link"
                          >
                            <a
                              href="mailto:biuro@kupauto24.de"
                              className="landing-block-node-menu-contactlink-link g-pa-10--md row align-items-center justify-content-center justify-content-sm-start justify-content-md-center justify-content-lg-start g-text-decoration-none--hover text-decoration-none"
                              target="_self"
                            >
                              <span className="landing-block-node-menu-contact-img-container text-lg-left g-font-size-18 g-line-height-1 d-none d-sm-inline-block g-color-primary col-12 g-pa-0">
                                <i
                                  className="landing-block-node-menu-contactlink-img icon icon-envelope fa fa-envelope-o"
                                  data-pseudo-url='{"text":"","href":"","target":"_self","enabled":false}'
                                />
                              </span>
                              <span className="landing-block-node-menu-contactlink-text-container text-sm-left text-lg-left d-inline-block col-12 g-pa-0">
                                <span className="landing-block-node-menu-contactlink-title  landing-block-node-menu-contact-title-style landing-semantic-text-medium g-color-main d-block text-uppercase g-font-size-13">
                                  <FormattedMessage id="writeToUsLabel" />
                                </span>
                                <span className="landing-block-node-menu-contactlink-text landing-block-node-menu-contact-text-style landing-semantic-link-small d-block g-color-main g-font-weight-700 g-text-decoration-none g-text-underline--hover">
                                  biuro@kupauto24.de
                                </span>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="landing-block-socials-container col-sm-4 col-md-3 g-mb-10 g-mb-0--md align-self-center">
                        <ul className="landing-block-cards-social list-inline g-pa-10--md g-mb-0 row align-items-center justify-content-center justify-content-sm-start">
                          <li
                            className="landing-block-card-social list-inline-item g-valign-middle g-mx-3 g-mb-6"
                            style={{ width: 'auto' }}
                            data-card-preset="facebook"
                          >
                            <a
                              className="text-center landing-block-card-social-icon-link d-block u-icon-v3 u-icon-size--sm g-rounded-50x g-bg-gray-light-v4 g-color-main g-bg-primary--hover g-color-white--hover g-font-size-14"
                              href="https://www.facebook.com/kupauto24.de/"
                              target="_blank"
                            >
                              <i
                                className="landing-block-card-social-icon fa-brands fa-facebook"
                                data-pseudo-url='{"text":"","href":"","target":"_self","enabled":false}'
                                style={{ paddingTop: '12px' }}
                              />
                            </a>
                          </li>
                          <li
                            className="landing-block-card-social list-inline-item g-valign-middle g-mx-3 g-mb-6"
                            data-card-preset="instagram"
                            style={{ width: 'auto' }}
                          >
                            <a
                              className="text-center landing-block-card-social-icon-link d-block u-icon-v3 u-icon-size--sm g-rounded-50x g-bg-gray-light-v4 g-color-main g-bg-primary--hover g-color-white--hover g-font-size-14"
                              href="https://www.instagram.com/kupauto24/"
                              target="_blank"
                            >
                              <i
                                className="landing-block-card-social-icon fa-brands fa-instagram"
                                data-pseudo-url='{"text":"","href":"","target":"_self","enabled":false}'
                                style={{ paddingTop: '12px' }}
                              />
                            </a>
                          </li>

                          <li
                            className="landing-block-card-social list-inline-item g-valign-middle g-mx-3 g-mb-6"
                            data-card-preset="youtube"
                            style={{ width: 'auto' }}
                          >
                            <a
                              className="text-center landing-block-card-social-icon-link d-block u-icon-v3 u-icon-size--sm g-rounded-50x g-bg-gray-light-v4 g-color-main g-bg-primary--hover g-color-white--hover g-font-size-14"
                              href="https://www.youtube.com/channel/UCKJLHneOtNYcMvyqKo7LQtw"
                              target="_blank"
                            >
                              <i
                                className="landing-block-card-social-icon fa-brands fa-youtube"
                                data-pseudo-url='{"text":"","href":"","target":"_self","enabled":false}'
                                style={{ paddingTop: '12px' }}
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="app__mobile-navbar d-lg-none">
              <section
                className="landing-block g-pb-30 g-pt-30 g-rounded-auto g-pl-80 g-pr-80 container"
                style={{
                  '--bg': '',
                  '--bg-url': '',
                  '--bg-url-2x': '',
                  '--bg-overlay': '',
                  '--bg-size': '',
                  '--bg-attachment': '',
                }}
              >
                <div className="landing-block-node-container container text-center">
                  <a href="/">
                    <img
                      className="landing-block-node-logo img-fluid g-mb-5 mb-5"
                      src="https://cdn.bitrix24.pl/b16609561/landing/33f/33ff88c3109e1acea89c490c43381ee0/logo-kupauto24-600_1x_1x.png"
                      alt="Logo"
                      style={{
                        position: 'relative',
                        cursor: 'pointer',
                      }}
                      data-fileid={454180}
                      data-pseudo-url='{"text":"","href":"","target":"_self","enabled":false}'
                      data-lazy-img="Y"
                      loading="lazy"
                    />
                  </a>
                </div>

                <ul className="landing-block-node-list row no-gutters list-inline g-mb-0 justify-content-around">
                  <li
                    className="landing-block-node-list-item col g-valign-middle g-flex-grow-0 list-inline-item g-mr-15 g-mr-0--last g-mb-0"
                    data-card-preset="facebook"
                  >
                    <div
                      className="landing-block-node-containericon g-pointer-events-all g-color-primary g-font-size-24 g-mr-auto g-ml-20"
                      style={{ '--color': '' }}
                    >
                      <a href="tel:+4920995509002">
                        <i className="landing-block-node-icon-1477 fa fa-phone"></i>
                      </a>
                    </div>
                  </li>
                  <li
                    className="landing-block-node-list-item col g-valign-middle g-flex-grow-0 list-inline-item g-mr-15 g-mr-0--last g-mb-0"
                    data-card-preset="instagram"
                  >
                    <div
                      className="landing-block-node-containericon g-pointer-events-all g-color-primary g-font-size-24 g-mr-auto"
                      style={{ '--color': '' }}
                    >
                      <a href="mailto:biuro@kupauto24.de">
                        <i className="landing-block-node-icon-1477 fa fa-envelope"></i>
                      </a>
                    </div>
                  </li>
                  <li
                    className="landing-block-node-list-item col g-valign-middle g-flex-grow-0 list-inline-item g-mr-15 g-mr-0--last g-mb-0"
                    data-card-preset="twitter"
                  >
                    <div
                      className="landing-block-node-containericon g-pointer-events-all g-color-primary g-font-size-24 g-mr-auto"
                      style={{ '--color': '' }}
                    >
                      <a target="_blank" href="https://www.facebook.com/kupauto24.de">
                        <i className="landing-block-node-icon-1477 fa-brands fa fa-facebook"></i>
                      </a>
                    </div>
                  </li>
                  <li
                    className="landing-block-node-list-item col g-valign-middle g-flex-grow-0 list-inline-item g-mr-15 g-mr-0--last g-mb-0"
                    data-card-preset="youtube"
                  >
                    <div
                      className="landing-block-node-containericon g-pointer-events-all g-color-primary g-font-size-24 g-mr-auto"
                      style={{ '--color': '' }}
                    >
                      <a target="_blank" href="https://www.instagram.com/kupauto24/">
                        <i className="landing-block-node-icon-1477 fab fa-instagram"></i>
                      </a>
                    </div>
                  </li>
                  <li
                    className="landing-block-node-list-item col g-valign-middle g-flex-grow-0 list-inline-item g-mr-15 g-mr-0--last g-mb-0"
                    data-card-preset="youtube"
                  >
                    <div
                      className="landing-block-node-containericon g-pointer-events-all g-color-primary g-font-size-24 g-ml-auto g-mr-20"
                      style={{ '--color': '' }}
                    >
                      <a target="_blank" href="https://www.youtube.com/channel/UCKJLHneOtNYcMvyqKo7LQtw/featured">
                        <i className="landing-block-node-icon-1477 fab fa-youtube"></i>
                      </a>
                    </div>
                  </li>
                </ul>
              </section>
            </div>

            <div
              className="landing-block-node-bottom-block landing-semantic-background-color u-header__section g-py-15--lg g-py-10 g-bg"
              data-header-fix-moment-classes="u-shadow-v18"
              style={{ '--bg': '#e33942' }}
            >
              <nav
                className="navbar navbar-expand-lg py-0 g-px-10 u-navbar-color--hover u-navbar-align-center u-navbar-color"
                style={{ '--navbar-color': '#ffffff', '--navbar-color--hover': 'hsla(0, 0%, 83%, 1)' }}
              >
                <div className="container">
                  <div className="collapse navbar-collapse align-items-center flex-sm-row g-mr-40--sm" id="navBar7878">
                    <ul className="landing-block-node-menu-list js-scroll-nav navbar-nav w-100 g-ml-minus-15--lg text-uppercase g-font-weight-700 g-font-size-13 g-py-10--md">
                      <li className="landing-block-node-menu-list-item nav-item g-mx-15--lg g-mb-7 g-mb-0--lg">
                        <a
                          href="https://kupauto24.de/"
                          className="landing-block-node-menu-list-item-link landing-semantic-menu-h-text nav-link p-0"
                        >
                          Start
                        </a>
                      </li>

                      <li className="landing-block-node-menu-list-item nav-item g-mx-15--lg g-mb-7 g-mb-0--lg">
                        <a
                          href="https://kupauto24.de/#b6840"
                          className="landing-block-node-menu-list-item-link landing-semantic-menu-h-text nav-link p-0"
                          target="_self"
                        >
                          <FormattedMessage id="missionLabel" />
                        </a>
                      </li>
                      <li className="landing-block-node-menu-list-item nav-item g-mx-15--lg g-mb-7 g-mb-0--lg">
                        <a
                          href="https://szukaj.kupauto24.de"
                          className="landing-block-node-menu-list-item-link landing-semantic-menu-h-text nav-link p-0"
                          target="_self"
                        >
                          <FormattedMessage id="searchCarLabel" />
                        </a>
                      </li>
                      <li className="landing-block-node-menu-list-item nav-item g-mx-15--lg g-mb-7 g-mb-0--lg">
                        <a
                          href="https://kupauto24.de/sprzedanesamochody/"
                          className="landing-block-node-menu-list-item-link landing-semantic-menu-h-text nav-link p-0"
                          target="_self"
                        >
                          <FormattedMessage id="carsSoldLabel" />
                        </a>
                      </li>
                      <li className="landing-block-node-menu-list-item nav-item g-mx-15--lg g-mb-7 g-mb-0--lg">
                        <a
                          href="https://kupauto24.de/#kontakt"
                          className="landing-block-node-menu-list-item-link landing-semantic-menu-h-text nav-link p-0"
                          target="_self"
                        >
                          <FormattedMessage id="contactLabel" />
                        </a>
                      </li>
                      <li className="landing-block-node-menu-list-item nav-item g-mx-15--lg g-mb-7 g-mb-0--lg">
                        <a
                          href="https://pomagamy.de"
                          className="landing-block-node-menu-list-item-link landing-semantic-menu-h-text nav-link p-0"
                          target="_blank"
                        >
                          pomagamy.de
                        </a>
                      </li>

                      <li className="landing-block-node-menu-list-item nav-item g-mx-15--lg g-mb-7 g-mb-0--lg">
                        <a
                          onClick={handleClickOnClipboard}
                          className="landing-block-node-menu-list-item-link landing-semantic-menu-h-text nav-link p-0"
                          target="_self"
                          role="button"
                        >
                          <FormattedMessage id="clipboardLabel" /> ({clipboardItems.length})
                        </a>
                      </li>
                    </ul>
                  </div>
                  <button
                    className="navbar-toggler btn g-pos-rel g-line-height-1 g-brd-none g-pa-0 ml-auto"
                    type="button"
                    aria-label="Toggle navigation"
                    aria-expanded="false"
                    aria-controls="navBar7878"
                    data-toggle="collapse"
                    data-target="#navBar7878"
                    onClick={handleToggleHamburger}
                  >
                    <span className={`hamburger hamburger--slider ${isActive ? 'is-active' : ''}`}>
                      <span className="hamburger-box">
                        <span className="hamburger-inner" />
                      </span>
                    </span>
                  </button>
                </div>
              </nav>
            </div>
          </header>
        </div>
      </div>

      <Clipboard show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default memo(Header);
