import { doRequest } from './base';

export const getCarMakeList = () => {
  return doRequest('/refdata/classes/Car/makes/');
};

export const getCarColorList = () => {
  return doRequest('/refdata/colors');
};

export const getCarClimatisationList = () => {
  return doRequest('/refdata/climatisations');
};

export const getCarDoorCountList = () => {
  return doRequest('/refdata/doorcounts');
};

export const getCarCategoryList = () => {
  return doRequest('/refdata/classes/Car/categories/');
};

export const getCarGearboxList = () => {
  return doRequest('/refdata/gearboxes');
};

export const getCarModelList = ({ queryKey }) => {
  const [_, mark] = queryKey;

  if (!mark.length > 0) return Promise.reject();

  const [obj] = mark;

  const encodeParam = encodeURI(obj.key);

  const url = `/refdata/classes/Car/makes/${encodeParam}/models`;

  return doRequest(url);
};

export const getCarFuelTypesList = () => {
  return doRequest('/refdata/fuels');
};

// add classification refdata to params: make and model and remove
export const parseSearchParams = (params) => {
  if (params.mark !== undefined) {
    params.classification = `refdata/classes/Car/makes/${params.mark}`;
    delete params.mark;

    if (params.model !== undefined) {
      params.classification = `${params.classification}/models/${params.model}`;
      delete params.model;
    }
  }

  return params;
};

export const getCarDetailRecord = ({ queryKey }) => {
  const [_, id] = queryKey;

  if (!id) return Promise.reject();

  const encodeParam = encodeURI(id);

  const url = `/search-api/ad/${encodeParam}`;

  return doRequest(url);
};

export const getSearchList = ({ queryKey }) => {
  const [_, params] = queryKey;

  return doRequest('/search-api/search', {
    params: {
      ...parseSearchParams(params),
      condition: 'USED',
      'imageCount.min': 3,
      'page.size': 25,
    },
  });
};

export const getTranslationMessages = ({ queryKey }) => {
  const [_, lang] = queryKey;

  if (!lang) return Promise.reject();

  const encodeParam = encodeURI(lang);

  const url = `${process.env.I18N_URL}${encodeParam}.json`;

  return doRequest(undefined, undefined, url);
};
