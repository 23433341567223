/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button, Col, Form, Row } from 'react-bootstrap';

import { useIsFetching, useQuery } from 'react-query';

import ExtendedSearchFields from 'components/ExtendedSearchFields';

import ControllerTypeahead from 'components/ControllerTypeahead';
import {
  getCarMakeList,
  getCarFuelTypesList,
  getCarModelList,
  getCarGearboxList,
  getCarCategoryList,
  getCarColorList,
  getCarClimatisationList,
  getCarDoorCountList,
} from 'api/api';
import {
  checkboxFields,
  damagedOptions,
  defaultValues,
  generatePageAllStats,
  mileageOptions,
  priceOptions,
  registerOptions,
  sortOptions,
} from 'utils/data';

import {
  flattenObjToFormOptions,
  isCustomId,
  isNewFormatCustomId,
  parseField,
  typeaheadShowAllOptions,
} from 'utils/utils';

import { buildDetailPath } from 'utils/routes';
import { toast } from 'react-toastify';
import styles from './styles.module.scss';

const SearchForm = ({ extendedSearch }) => {
  const { isLoading: isLoadingCarMake, data: dataCarMake } = useQuery('carMake', getCarMakeList);
  const { isLoading: isLoadingFuelType, data: dataFuelType } = useQuery('petrolType', getCarFuelTypesList);
  const { isLoading: isLoadingGearboxList, data: dataCarGearboxList } = useQuery('gearboxList', getCarGearboxList);
  const { isLoading: isLoadingCategoryList, data: dataCarCategoryList } = useQuery('categoryList', getCarCategoryList);
  const { isLoading: isLoadingColorList, data: dataCarColorList } = useQuery('colorList', getCarColorList);
  const { isLoading: isLoadingDoorCountList, data: dataCarDoorCountList } = useQuery(
    'doorCountList',
    getCarDoorCountList,
  );
  const { isLoading: isLoadingClimatisiationList, data: dataCarClimatisationList } = useQuery(
    'climatisationList',
    getCarClimatisationList,
  );

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const intl = useIntl();
  const isFetching = useIsFetching();

  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);
  const [randomNumber, setRandomNumber] = useState(null);
  const [isVisibleMoreFilters, setIsVisibleMoreFilters] = useState(false);
  const isMounted = useRef(true);

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit, watch, setValue, getValues, reset } = methods;

  const markField = watch('mark');
  const powerMinField = watch('power_min');
  const powerMaxField = watch('power_max');

  const priceMinField = watch('price_min');
  const priceMaxField = watch('price_max');

  const mileageMinField = watch('mileage_min');
  const mileageMaxField = watch('mileage_max');

  const firstRegistrationDateMinField = watch('firstRegistrationDate_min');
  const firstRegistrationDateMaxField = watch('firstRegistrationDate_max');

  const itemsCarMake = dataCarMake?.data?.reference?.item;
  const itemsFuelTypes = dataFuelType?.data?.reference?.item;
  const itemsCarGearbox = dataCarGearboxList?.data?.reference?.item;
  const itemsCarCategory = dataCarCategoryList?.data?.reference?.item;
  const itemsCarColor = dataCarColorList?.data?.reference?.item;
  const itemsClimatisation = dataCarClimatisationList?.data?.reference?.item;
  const itemsDoorCount = dataCarDoorCountList?.data?.reference?.item;

  const markOptions = flattenObjToFormOptions(itemsCarMake);

  const fuelTypesOptions = flattenObjToFormOptions(itemsFuelTypes);
  const gearboxOptions = flattenObjToFormOptions(itemsCarGearbox);
  const categoryOptions = flattenObjToFormOptions(itemsCarCategory);
  const colorOptions = flattenObjToFormOptions(itemsCarColor);
  const climatisationOptions = flattenObjToFormOptions(itemsClimatisation);
  const doorCountOptions = flattenObjToFormOptions(itemsDoorCount);

  const { isLoading: isLoadingCarModelList, data: dataCarModelList } = useQuery(
    ['carModel', markField],
    getCarModelList,
    {
      enabled: markField.length > 0,
    },
  );

  let modelOptions = [];
  if (!isLoadingCarModelList) {
    const itemsCarModelList = dataCarModelList?.data?.reference?.item;

    modelOptions = flattenObjToFormOptions(itemsCarModelList);
    const itemAll = { key: 'all', label: intl.formatMessage({ id: 'allLabel' }) };
    modelOptions = [itemAll, ...modelOptions];
  }

  const onNavigate = (data) => {
    Object.entries(data).forEach(([key, value]) => {
      // checkboxes
      if (Object.keys(checkboxFields).includes(key)) {
        value ? searchParams.set(key, checkboxFields[key]) : searchParams.delete(key);
      } else if (value) {
        // select and multiselect fields
        value[0] ? searchParams.set(key, parseField(value)) : searchParams.delete(key);
      }
    });

    navigate({ pathname: '/search', search: searchParams.toString(), replace: false });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [searchParams]);

  // clear model when changing mark field
  const onChangeHandler = (value) => {
    setValue('mark', value);

    setTimeout(() => {
      setValue('model', []); // for multilanguage fields
    }, 500);
  };

  // Get form values from params
  useEffect(() => {
    if (
      !itemsFuelTypes ||
      !itemsCarGearbox ||
      !itemsCarCategory ||
      !itemsCarColor ||
      !itemsCarMake ||
      !itemsClimatisation ||
      !itemsDoorCount
    )
      return;

    const multiLanguageFields = {
      mark: markOptions,
      model: modelOptions,
      fuel: fuelTypesOptions,
      gearbox: gearboxOptions,
      category: categoryOptions,
      exteriorColor: colorOptions,
      sort_field: sortOptions(intl),
      damageUnrepaired: damagedOptions(intl),
      climatisation: climatisationOptions,
      doorCount: doorCountOptions,
    };

    searchParams.forEach(function (value, key) {
      // translate from query params

      if (multiLanguageFields[key] !== undefined) {
        const splittedValueArr = value.split(','); // Split Array URLSearchParams => category=EstateCar%2CSmallCar

        const tmpArr = [];
        splittedValueArr.forEach(function (splittedValue) {
          tmpArr.push({
            key: splittedValue,
            label: multiLanguageFields[key].find((e) => e.key === splittedValue)?.label || splittedValue,
          });
        });

        if (!getValues(key).length) {
          setValue(key, tmpArr);
        }
      } else setValue(key, [value]);
    });
  }, [searchParams, isFetching]);

  const onChangeHandlerForm = () => {
    setIsActiveFilter(true);
    setIsDisabledSubmit(false);
  };

  const onResetFormHandler = () => {
    reset();
    setIsActiveFilter(false);
  };

  const onClickSubmit = (e) => {
    setTimeout(() => {
      if (isMounted.current) {
        setIsDisabledSubmit(true); // for multilanguage fields
      }
    });

    if (isActiveFilter) return;

    e.preventDefault();
    toast(intl.formatMessage({ id: 'selectParameterLabel' }));
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!isFetching) {
      const subscription = watch(() => onChangeHandlerForm());
      return () => subscription.unsubscribe();
    }
  }, [watch, isFetching]);

  const resetMinField = (maxField, minField, keyField) => {
    if (parseInt(maxField, 10) < parseInt(minField, 10)) {
      setValue(keyField, []);
    }
  };

  useEffect(() => {
    resetMinField(powerMaxField, powerMinField, 'power_min');
    resetMinField(firstRegistrationDateMaxField, firstRegistrationDateMinField, 'firstRegistrationDate_min');

    resetMinField(priceMaxField, priceMinField, 'price_min');
    resetMinField(mileageMaxField, mileageMinField, 'mileage_min');
  }, [powerMaxField, firstRegistrationDateMaxField, priceMaxField, mileageMaxField]);

  let modelDescription = watch('modelDescription');

  // Redirect to id ad from description modelDescription field (without validation - limit request from api)
  useEffect(() => {
    if (!isFetching && modelDescription && isCustomId(modelDescription)) {
      if (isNewFormatCustomId(modelDescription)) {
        modelDescription = modelDescription
          .toString()
          .toLowerCase()
          .replace(/^24|ka$/g, '');
      }

      const redirectUrl = buildDetailPath(modelDescription, 301);
      navigate(redirectUrl);
    }
  }, [modelDescription]);

  useEffect(() => {
    setRandomNumber(generatePageAllStats().toLocaleString('pl-PL'));

    return () => {
      isMounted.current = false;
    };
  }, []);

  const toggleButton = () => {
    setIsVisibleMoreFilters(!isVisibleMoreFilters);
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <div
            className="mb-5 landing-block-node-inner container g-max-width-container text-uppercase u-heading-v2-4--bottom g-border-color text-center g-border-color--hover"
            style={{ '--border-color': 'var(--primary)', '--border-color--hover': 'var(--primary)' }}
          >
            <h6 className="landing-block-node-subtitle landing-semantic-subtitle-medium-primary g-font-weight-700 g-letter-spacing-1 g-color-primary g-mb-20">
              {' '}
            </h6>
            <h2 className="landing-block-node-title landing-semantic-title-medium h1 u-heading-v2__title g-line-height-1_3 g-font-weight-700 g-mb-minus-10">
              {extendedSearch ? (
                <FormattedMessage id="searchCarLabel" />
              ) : (
                <FormattedMessage id="searchCarWithStatsLabel" values={{ value: randomNumber }} />
              )}
            </h2>
          </div>

          <p className="mb-5 landing-block-node-title landing-semantic-title-medium g-my-0 container g-max-width-container g-pl-15 g-pr-15 text-center g-color g-font-weight-400">
            <FormattedMessage id="selectParameterLabel" />
          </p>
        </Col>
      </Row>

      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onNavigate)} className={styles.formWrapper}>
          <Row>
            <Col md={3}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="field-mark">
                  <FormattedMessage id="markLabel" />
                </Form.Label>

                <ControllerTypeahead
                  name="mark"
                  disabled={isLoadingCarMake}
                  options={markOptions}
                  placeholderIdLabel="markLabel"
                  onChangeHandler={onChangeHandler}
                  clearButton
                  filterBy={typeaheadShowAllOptions}
                />
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="field-model">
                  <FormattedMessage id="modelLabel" />
                </Form.Label>

                <ControllerTypeahead
                  name="model"
                  disabled={isLoadingCarModelList || !getValues('mark').length}
                  options={modelOptions}
                  placeholderIdLabel="modelLabel"
                  clearButton
                  filterBy={typeaheadShowAllOptions}
                />
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="field-price-min">
                  <FormattedMessage id="priceLabel" /> (€)
                </Form.Label>

                <Row>
                  <Col md={6}>
                    <ControllerTypeahead
                      name="price_min"
                      options={priceOptions}
                      placeholderIdLabel="fromLabel"
                      clearButton
                      allowNew
                    />
                  </Col>

                  <Col md={6}>
                    <ControllerTypeahead
                      name="price_max"
                      options={priceOptions}
                      placeholderIdLabel="toLabel"
                      clearButton
                      allowNew
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group className="mb-3 app__model-description-wrapper">
                <Form.Label htmlFor="field-price-min">
                  <FormattedMessage id="modelDescription" />
                </Form.Label>

                <ControllerTypeahead
                  name="modelDescription"
                  options={[]}
                  placeholderIdLabel="modelDescriptionPlaceholderLabel"
                  clearButton
                  allowNew
                  open={false}
                  filterBy={() => true}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="field-register-from">
                  <FormattedMessage id="first-registrationLabel" />
                </Form.Label>

                <Row>
                  <Col md={6}>
                    <ControllerTypeahead
                      name="firstRegistrationDate_min"
                      options={registerOptions}
                      placeholderIdLabel="fromLabel"
                      clearButton
                      allowNew
                    />
                  </Col>

                  <Col md={6}>
                    <ControllerTypeahead
                      name="firstRegistrationDate_max"
                      options={registerOptions}
                      placeholderIdLabel="toLabel"
                      clearButton
                      allowNew
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="field-mileage-from">
                  <FormattedMessage id="mileageLabel" /> (km)
                </Form.Label>

                <Row>
                  <Col md={6}>
                    <ControllerTypeahead
                      name="mileage_min"
                      options={mileageOptions}
                      placeholderIdLabel="fromLabel"
                      clearButton
                      allowNew
                    />
                  </Col>

                  <Col md={6}>
                    <ControllerTypeahead
                      name="mileage_max"
                      options={mileageOptions}
                      placeholderIdLabel="toLabel"
                      clearButton
                      allowNew
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="field-fuel-types">
                  <FormattedMessage id="fuelLabel" />
                </Form.Label>

                <ControllerTypeahead
                  name="fuel"
                  disabled={isLoadingFuelType}
                  options={fuelTypesOptions}
                  placeholderIdLabel="fuelLabel"
                  clearButton
                  multiple
                />
              </Form.Group>
            </Col>

            <Col md={3} className={`${isVisibleMoreFilters ? '' : 'd-flex'}`}>
              {!extendedSearch && (
                <Button
                  variant="warning"
                  type="submit"
                  className={`mb-3 align-self-end w-100 ${!isActiveFilter ? 'disabled' : ''}`}
                  style={{ pointerEvents: 'auto' }}
                  onClick={onClickSubmit}
                >
                  <FormattedMessage id="submitLabel" />
                </Button>
              )}

              {isVisibleMoreFilters && (
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="field-damaged">
                    <FormattedMessage id="damagedFormLabel" />
                  </Form.Label>

                  <ControllerTypeahead
                    name="damageUnrepaired"
                    options={damagedOptions(intl)}
                    placeholderIdLabel="damagedFormLabel"
                    clearButton
                    filterBy={() => true}
                  />
                </Form.Group>
              )}
            </Col>
          </Row>

          {!extendedSearch && isActiveFilter && (
            <Row>
              <Col md={9}></Col>
              <Col md={3} className="text-center">
                <Button variant="secondary" type="button" className="w-100" onClick={() => onResetFormHandler()}>
                  <FormattedMessage id="clearFiltersLabel" />
                </Button>
              </Col>
            </Row>
          )}

          {extendedSearch && (
            <ExtendedSearchFields
              gearboxOptions={gearboxOptions}
              categoryOptions={categoryOptions}
              colorOptions={colorOptions}
              climatisationOptions={climatisationOptions}
              doorCountOptions={doorCountOptions}
              toggleButton={toggleButton}
              onClickSubmit={onClickSubmit}
              isDisabledSubmit={isDisabledSubmit || !isActiveFilter}
              isVisibleMoreFilters={isVisibleMoreFilters}
            />
          )}
        </Form>
      </FormProvider>
    </>
  );
};

SearchForm.defaultProps = {
  extendedSearch: false,
};

SearchForm.propTypes = {
  extendedSearch: PropTypes.bool,
};

export default SearchForm;
